
import { TUser } from '@/types'
import { defineComponent, PropType } from 'vue'
import { mapGetters } from 'vuex'
import moment from 'moment-with-locales-es6'
import { FractionV3 } from '@/globals/javascript/models/resources-version-3/FractionsV3'
import {
  ILogChangeV3, ILogV3,
} from '@/types/resources-version-3'
import { MaterialV3 } from '@/globals/javascript/models/resources-version-3/MaterialV3'
import { TypeV3 } from '@/globals/javascript/models/resources-version-3/TypeV3'
import { IPriceItem } from '@/types/prices'

interface ILogEntry {
  id: number,
  title: string
  changesArray: string[]
}

export default defineComponent({
  name: 'ChangeLogV3',
  props: {
    itemToEdit: {
      type: Object as PropType<FractionV3 | MaterialV3 | TypeV3 | IPriceItem
        | {
          [key:string]: unknown
          logs: ILogV3[]
        }>,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'getUserByID',
      'selectedLanguage',
    ]),
    logEntries(): ILogEntry[] {
      const logEntries: ILogEntry[] = []

      this.itemToEdit.logs.forEach((log: ILogV3, index: number) => {
        const user: TUser = this.getUserByID(log.userID)
        const time = moment(new Date(log.timestamp))
        time.locale(this.selectedLanguage)

        const title = index === 0
          ? this.mixWB('CREATED_BY_X_WITH_DATE', [user.fullName, time.format('lll')])
          : this.mixWB('EDITED_BY_X_WITH_DATE', [user.fullName, time.format('lll')])

        const changesArray: string [] = []
        log.changes.forEach((logChange: ILogChangeV3) => {
          if (logChange.type === 'array') {
            changesArray.push(this.mixWB('CHANGES_IN_LIST_X', [logChange.key]))
            return
          }
          changesArray.push(this.mixWB(
            'X_TO_Y_FROM_Z',
            [logChange.key, logChange.newValue, logChange.oldValue],
          ))
        })

        logEntries.push({
          id: log.timestamp,
          title,
          changesArray,
        })
      })

      return logEntries
    },
  },
})
